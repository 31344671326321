import { useState } from "react";
import { gcd } from "../../../../lib/math/gcd";
import { Division } from "./Division";
import styles from './simpledivision.module.css';
import { DivisionValue } from "./types";

const simplify = (n: number, d: number): DivisionValue => {
  let gcd_nd;

  while ((gcd_nd = gcd(n, d)) > 1) {
    n = n / gcd_nd;
    d = d / gcd_nd;
  }

  return { n, d };
};

const SimpleDivision = () => {

  const[value, setValue] = useState<DivisionValue>({n: 1, d: 2});
  const[result, setResult] = useState<DivisionValue | null>(null);

  const handleSimplify = () => {
    setResult(simplify(value.n, value.d));
  }

  const handleValueChange = (divisionValue: DivisionValue) => {
    setValue(divisionValue);
    setResult(null);
  }

  let resultDisplay = null;

  if (result) {
    resultDisplay = (
      <Division {...result} />);
  }

  return (
    <div
      className={styles.simple_division}
    >
      <Division
        {...value}
        allowChange={true}
        onChange={handleValueChange}
      />
      <div
        className={styles.simple_division__action}
      >
        <button onClick={handleSimplify}>Simplify</button>
      </div>
      {resultDisplay}
    </div>
  )
};

export { SimpleDivision };