import { useEffect, useRef } from 'react';
import styles from './division.module.css';
import { DivisionValue } from './types';

/**
 * Display division with numerator and denumerator
 */
type Props = {
  n: number;
  d: number;
  allowChange?: boolean;
  onChange?: (value: DivisionValue) => void;
};

const Division = ({ n, d, allowChange, onChange }: Props) => {
  const nRef = useRef<HTMLDivElement>(null);
  const dRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let nVal = n;
    let dVal = d;

    const valueChanged = () => {
      if (onChange) {
        onChange({ n: nVal, d: dVal });
      }
    };

    // content editable take over
    const nDiv = nRef.current as HTMLDivElement;
    const dDiv = dRef.current as HTMLDivElement;

    nDiv.innerText = `${nVal}`;
    dDiv.innerText = `${dVal}`;

    nDiv.addEventListener('blur', () => {
      const newN = parseInt(nDiv.innerText);
      nVal = newN;
      valueChanged();
    });

    dDiv.addEventListener('blur', () => {
      const newD = parseInt(dDiv.innerText);
      dVal = newD;
      valueChanged();
    });
  }, []);

  return (
    <div
      className={styles.division}
    >
      <div
        className={styles.division__numerator}
        contentEditable={allowChange}
        ref={nRef}
      >
      </div>
      <div
        className={styles.division__line}
      >
      </div>
      <div
        ref={dRef}
        className={styles.division__denominator}
        contentEditable={allowChange}
      >
      </div>
    </div>
  );
}

export { Division };
