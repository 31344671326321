/** Great common demoniator */
const gcd = (n: number, m: number): number => {
  if (n > m) {
    const temp = n;
    n = m;
    m = temp;
  }
  
  while (n > 1) {
    let r = m % n;
    
    if (r === 0) {
      break; // we found gcd
    }

    m = n;
    n = r;
  }

  return n;
}

export { gcd }