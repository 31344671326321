import React from 'react';
import { SimpleDivision } from './components/math/fraction/Division/SimpleDivision';

function App() {
  return (
    <div className="App">
      <SimpleDivision />
    </div>
  );
}

export default App;
